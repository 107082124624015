import Vue from 'vue'
import Vuex from 'vuex'

import toast from './toast-notification'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appBarOpen: false,
  },
  mutations: {
    setAppBarState (state, payload) {
      state.appBarOpen = payload
    }
  },
  actions: {
  },
  modules: {
    toast
  }
})
