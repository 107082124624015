<template>
  <div class="home">
    <v-img id="frontImage" src="@/assets/images/frontPage.jpg" class="align-center">
      <div class="d-flex align-center justify-center">
        <div class="py-6 px-12" id="mainCard">
          <h1 class="white--text" :style="{'line-height': isOnPC ? '35px' : '22px'}" :class="isOnPC ? 'text-h4' : 'text-h6'">
            Gereja Kristen Bahtera Injil (GKBI)<br/>
            Jemaat Ekklesia Kristus Karombasan
          </h1>
          <p style="line-height: 20px; text-align: center" class="mt-2 font-weight-bold white--text">Gembala Sidang: Pdt. Jeanne Ante, S.Th.</p>
        </div>
      </div>
    </v-img>
    <div id="churchId" class="d-flex align-center pa-4">
      <img :class="!isOnPC ? 'mr-2' : null" :style="{ width: isOnPC ? '100px' : '70px' }" src="@/assets/logo.png" />
      <div class="d-inline-block" :class="isOnPC ? 'text-body-1' : 'text-body-2'">
        <p>Alamat: Jl. Tonsawang &mdash; Karombasan Ling. II Manado</p>
        <p>E-mail: <a href="mailto:gkbiekklesiakristus@gmail.com">gkbiekklesiakristus@gmail.com</a></p>
      </div>
    </div>

    <v-container>
      <div id="abstract" class="mt-3">
        <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum molestie lorem non ligula sodales,
          nec pretium nibh luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames
          ac turpis egestas. Donec sit amet maximus neque. In eget quam id est accumsan lobortis. Nullam
          vulputate eu nisl in rutrum. Etiam dictum diam sit amet tortor vehicula, ut fermentum dolor ullamcorper.
          Nam sit amet tellus sit amet ipsum interdum maximus sed in nibh. Etiam sit amet sapien ipsum. Fusce
          pellentesque, leo nec consectetur vehicula, tortor velit lacinia nisi, ut porttitor arcu purus ac libero.
          Sed ut fringilla quam, id auctor augue.
        </p> -->
      </div>
      <LatestNews />
      <div id="googleMaps">
        <h3 class="text-h4">Peta</h3>
        <iframe
          style="border: 0; width: 100%; height: 500px"
          :src="`https://www.google.com/maps/embed/v1/place?key=${googleMapsApiKey}&q=GKBI+Karombasan+Manado`"
        />
      </div>
      <div id="video">
        <h3 class="text-h4">Video</h3>
        <iframe
          src="https://www.youtube.com/embed/rifOElQqgMo"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          style="width: 100%; height: 400px"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import LatestNews from '@/components/HomePage/LatestNews'

export default {
  name: 'Home',
  components: {
    // HelloWorld
    LatestNews
  },
  computed: {
    googleMapsApiKey: () => process.env.VUE_APP_GOOGLE_API_KEY,
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  }
}
</script>

<style scoped>
#frontImage div {
  height: 100%;
  position: relative;
}

#mainCard {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
}

#mainCard p, #mainCard h1 {
  font-family: serif !important;
  line-height: 32px;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
}

#churchId > * {
  vertical-align: center;
}

#churchId p {
  margin: 0;
}

#churchId img {
  width: 100px;
  margin-right: 15px;
}
</style>
