<template>
  <div class="mb-5" v-if="!loading">
    <div class="d-flex align-center">
      <div style="width: 100%">
        <h2 class="text-h5">Berita Terbaru</h2>
        <hr />
      </div>
      <!-- <v-btn color="primary" outlined class="ml-6" to="/berita">Lihat Semua Berita</v-btn> -->
    </div>
    <v-container class="ma-0 pa-0" fluid>
    </v-container>
  </div>
</template>

<script>
// import JumbotronVue from '../Jumbotron.vue'
import firebase from '@/plugins/firebase'
// import NewsCardVue from '../NewsCard.vue'

export default {
  name: 'LatestNewsComponent',

  components: {
    // jumbotron: JumbotronVue
    // 'news-card': NewsCardVue
  },

  data () {
    return ({
      loading: false,
      newsfeed: []
    })
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },

  methods: {
    getLatestNews () {
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('news').where('published', '==', true).orderBy('datePublished', 'desc').limit(5).get()
          .then(snapshot => {
            var resolution = snapshot.docs.map(doc => Object.assign({ id: doc.id }, doc.data()))
            resolve(resolution)
          })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true
    try {
      this.$data.newsfeed = await this.getLatestNews()
    } catch (e) {
      this.$store.commit('toast/setError', 'Gagal mengambil daftar berita.')
    }
    this.$data.loading = false
  }
}
</script>
