<template>
	<component :is="appBarComponent" />
</template>

<script>
import DesktopAppBar from '@/components/AppBar/DesktopAppBar'
import MobileAppBar from '@/components/AppBar/MobileAppBar'

export default {
	name: 'AppBar',

	computed: {
		appBarComponent () {
			return this.$vuetify.breakpoint.mdAndUp ? DesktopAppBar : MobileAppBar
		}
	}
}
</script>
