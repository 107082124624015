<template>
  <v-app-bar app color="primary" dark class="align-center">
        <v-img class="mt-4" max-width="70px" src="@/assets/logo.png" />
        <div class="d-flex">
          <v-btn text dark href="https://gkbi.or.id">Situs Sinode</v-btn>
          <v-btn text dark to="/">Beranda</v-btn>
        </div>
  </v-app-bar>
</template>

<script>
export default {
  name: 'DesktopAppBar',
  
  computed: {
    isOnPC () { return this.$vuetify.breakpoint.mdAndUp }
  }
}
</script>
