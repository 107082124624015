// import Vuex from 'vuex'

export default {
  namespaced: true,
  state: {
    content: null,
    color: null
  },
  mutations: {
    setNotification (state, payload) {
      if (typeof payload !== 'object') {
        state.content = payload
      }
      state.content = payload.content
      state.color = payload.color ? payload.color : null
    },
    setError (state, payload) {
      state.content = payload
      state.color = 'error'
    },
    clear (state) {
      var s = Object.assign(state, {
        content: null,
        color: null
      })

      state = s
    }
  },
  getters: {
    notification (state) {
      return state
    }
  }
}
