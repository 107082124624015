import firebase from '@firebase/app'
import '@firebase/firestore'
import '@firebase/auth'
import '@firebase/functions'
import '@firebase/storage'

const firebaseConfig = {
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
    authDomain: 'gkbi-1.firebaseapp.com',
    projectId: 'gkbi-1',
    databaseURL: 'https://gkbi-1.firebaseio.com',
    storageBucket: process.env.VUE_APP_GCS_BUCKET_NAME,
    messagingSenderId: '639228597940',
    appId: '1:639228597940:web:27675fd01e90393b35e9e1',
    measurementId: 'G-JWNLPGEXV2'
}

firebase.initializeApp(firebaseConfig)

export default firebase
