import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import id from 'vuetify/lib/locale/id';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#006377',
        secondary: '#57b6c9',
        accent: '#ffd200',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
    lang: {
      locales: { id },
      current: 'id',
    },
});
