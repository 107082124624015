<template>
  <v-app>
    <AppBar v-if="!$route.meta.fullscreen" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import AppBar from '@/components/AppBar/AppBar'

export default {
  name: 'App',

  components: {
    AppBar
  },

  data: () => ({
    //
  }),
};
</script>

<style>
p {
  text-align: justify;
}
</style>
