<template>
  <div>
    <v-app-bar app dark color="primary">
      <v-app-bar-nav-icon @click="appBarOpen = !appBarOpen" />
      <v-spacer />
      <v-img class="mt-3" contain max-width="65px" src="@/assets/logo.png" />
      <v-spacer />
      <div style="width: 25px">&nbsp;</div>
    </v-app-bar>
    <v-navigation-drawer app v-model="appBarOpen">
      <v-subheader>Menu</v-subheader>
      <v-list nav dense subheader>
        <v-list-item href="https://gkbi.or.id">
          <v-list-item-icon><v-icon>mdi-bank</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Situs Sinode</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/">
          <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Beranda</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: 'MobileAppBar',

  computed: {
    appBarOpen: {
      get () { return this.$store.state.appBarOpen },
      set (v) { this.$store.commit('setAppBarState', v) }
    }
  }
}
</script>
